import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const getLocalizedCities = (t) => {
  const citiesGermany = t('common:cities', { returnObjects: true });
  const citiesAustria = t('common:citiesAustria', {
    returnObjects: true,
  });
  const citiesSwitzerland = t('common:citiesSwitzerland', {
    returnObjects: true,
  });
  const citiesDenmark = t('common:citiesDenmark', {
    returnObjects: true,
  });
  const citiesUK = t('common:citiesUK', {
    returnObjects: true,
  });

  return {
    ...citiesGermany,
    ...citiesAustria,
    ...citiesSwitzerland,
    ...citiesDenmark,
    ...citiesUK,
  };
};

export const getLocalizedCity = (city, t) => getLocalizedCities(t)[city];

/**
 * Returns a translate function, that translates a city key,
 * regardless of which country namespace it is under
 */
export const useLocalizeCity = () => {
  const cities = useAllCity();
  const translateCity = useCallback((city) => cities[city], [cities]);
  return translateCity;
};

export const useLocalizedCity = (city) => {
  const translateCity = useLocalizeCity();
  return translateCity(city);
};

const useAllCity = () => {
  const { t } = useTranslation('common');

  const allCities = useMemo(() => getLocalizedCities(t), [t]);

  return allCities;
};
